import { onMounted, onUnmounted, ref } from 'vue';
import { mockDataService } from '@/services/MockDataService';

export function useMockData() {
  const currentBlock = ref(mockDataService.currentBlock);
  const isSimulating = ref(false);

  const startSimulation = () => {
    if (isSimulating.value) return;
    console.log('Starting simulation...'); // Debug log
    
    // Start block simulation
    mockDataService.startBlockSimulation();
    
    // Start new pairs simulation
    mockDataService.startNewPairSimulation();
    
    isSimulating.value = true;
  };

  const stopSimulation = () => {
    console.log('Stopping simulation...'); // Debug log
    mockDataService.stopAllSimulations();
    isSimulating.value = false;
  };

  const startPairSimulation = (pair) => {
    mockDataService.startSwapSimulation(pair);
  };

  const stopPairSimulation = (pairAddress) => {
    mockDataService.stopSimulation(`swaps_${pairAddress}`);
  };

  // Listen for block updates
  const handleBlockUpdate = (event) => {
    console.log('Block update received:', event.detail); // Debug log
    currentBlock.value = event.detail.blockNumber;
  };

  onMounted(() => {
    window.addEventListener('blockUpdate', handleBlockUpdate);
    // Initialize with current block
    currentBlock.value = mockDataService.currentBlock;
  });

  onUnmounted(() => {
    window.removeEventListener('blockUpdate', handleBlockUpdate);
    stopSimulation();
  });

  return {
    currentBlock,
    isSimulating,
    startSimulation,
    stopSimulation,
    startPairSimulation,
    stopPairSimulation
  };
} 