import { createAppKit } from '@reown/appkit';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';

class WalletProvider {
  constructor() {
    this.appKit = null;
    this.isConnecting = false;
  }

  async connect() {
    if (this.isConnecting) {
      throw new Error("Connection already in progress");
    }

    try {
      this.isConnecting = true;

      if (!this.appKit) {
        this.appKit = createAppKit({
          projectId: import.meta.env.VITE_WALLET_CONNECT_ID,
          metadata: {
            name: 'Block Compass',
            description: 'Your blockchain analytics companion',
            url: window.location.origin,
          },
          adapter: new EthersAdapter()
        });
      }

      const connection = await this.appKit.connect();
      console.log("Connected Wallet Address:", connection.address);
      return { provider: this.appKit, address: connection.address };
    } catch (error) {
      console.error("Wallet Connection Error:", error);
      this.cleanup();
      throw error;
    } finally {
      this.isConnecting = false;
    }
  }

  async disconnect() {
    try {
      if (this.appKit) {
        await this.appKit.disconnect();
        console.log("Disconnected Wallet");
      }
    } catch (error) {
      console.error("Disconnect Error:", error);
      throw error;
    } finally {
      this.cleanup();
    }
  }

  cleanup() {
    this.appKit = null;
    this.isConnecting = false;
  }

  isConnected() {
    return !!this.appKit && this.appKit.isConnected();
  }

  getProvider() {
    return this.appKit;
  }
}

// Create a singleton instance
const walletProvider = new WalletProvider();

export const connectWallet = () => walletProvider.connect();
export const disconnectWallet = () => walletProvider.disconnect();
export const isWalletConnected = () => walletProvider.isConnected();
export const getWalletProvider = () => walletProvider.getProvider();
