<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const menuItems = ref([
    { label: 'Home', icon: 'pi pi-home', to: '/' },
    { label: 'Pairs', icon: 'pi pi-chart-line', to: '/pairs' },
    { label: 'Trending', icon: 'pi pi-trending-up', to: '/trending' },
    { label: 'Discord', icon: 'pi pi-discord', to: '/' },
    { label: 'Info', icon: 'pi pi-info-circle', to: '/tradingview' }
]);

const isActive = (path) => route.path === path;
</script>

<template>
    <nav class="mobile-menu">
        <router-link 
            v-for="item in menuItems" 
            :key="item.to" 
            :to="item.to"
            class="mobile-menu-item"
            :class="{ 'active': isActive(item.to) }"
        >
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
        </router-link>
    </nav>
</template>

<style lang="scss" scoped>
.mobile-menu {
    display: none; // Hidden by default

    @media (max-width: 991px) {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;
        background-color: var(--surface-overlay);
        backdrop-filter: blur(10px);
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
        z-index: 999;
        justify-content: space-around;
        align-items: center;
        padding: 0 1rem;
        margin-bottom: 0rem;
    }
}

.mobile-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-decoration: none;
    color: var(--text-color);
    padding: 0.5rem;
    gap: 0.25rem;
    transition: color 0.2s;

    i {
        font-size: 1.25rem;
    }

    span {
        font-size: 0.75rem;
    }

    &.active {
        color: var(--primary-color);
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--primary-color);
        }
    }
}
</style> 