import AppLayout from '@/layout/AppLayout.vue';
import AuthenticatedLayout from '@/layout/AuthenticatedLayout.vue';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            //render all as children of the layout
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: () => import('@/views/Dashboard.vue')
                },

            ]
        },
        {
            path: '/pairs',
            component: AppLayout,
            children: [
                {
                    path: '/pairs',
                    name: 'pairs',
                    component: () => import('@/views/Pairs.vue')
                },
                {
                    path: ':pair_address',
                    name: 'pair-info',
                    component: () => import('@/views/PairInfo.vue'),
                    props: true
                },
          
            ]
        },
        {
            path: '/trending',
            component: AppLayout,
            children: [
                {
                    path: '/trending',
                    name: 'trending',
                    component: () => import('@/views/Trending.vue')
                },
         
            ]
        },
        {
            path: '/transactions',
            component: AppLayout,
            children: [
                {
                    path: '/transactions',
                    name: 'transactions',
                    component: () => import('@/views/Trending.vue')
                },
         
            ]
        },
        {
            path: '/tradingview',
            component: AppLayout,
            children: [
                {
                    path: '/tradingview',
                    name: 'tradingview',
                    component: () => import('@/views/TradingView.vue')
                },
                {
                    path: '/charts',
                    name: 'charts',
                    component: () => import('@/views/Charts.vue')
                }
            ]
        },
        {
            path: '/tx',
            component: AppLayout,
            children: [
                {
                    path: ':txHash',
                    name: 'Transaction',
                    component: () => import('@/views/Transaction.vue'),
                    props: true
                }
            ]
        },
    ]
});

export default router;
