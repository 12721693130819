
import {ref} from 'vue';

window.Pusher = Pusher;

const pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
    cluster: import.meta.env.VITE_PUSHER_CLUSTER,
    forceTLS: true,
});

const swapData = ref([]);
const candleData = ref({
    open: null,
    high: null,
    low: null,
    close: null,
    volume: null,
    time: null,
});
const dataStore = ref({
    currentPrice: null,
    currentPriceBase: null,
    tokenReserves: null,
    baseReserves: null,
    contractBalance: null,
    ownerBalance: null,
    deployerBalance: null,
    five_min: null,
    one_hour: null,
    six_hour: null,
    twenty_four_hour: null,
});

const liveBlockData = ref([]);

const subscribeToSwapChannel = (channelName, pair) => {
    const channel = pusher.subscribe(channelName);

    channel.bind('swap', (event) => {
        const data = JSON.parse(event.message);

        if (data.swap) {
            const formattedSwap = formatSwap(data.swap, pair);
            swapData.value = [formattedSwap];
        }

        if (data.candle) {
            candleData.value = {
                o: data.candle.o,
                h: data.candle.h,
                l: data.candle.l,
                c: data.candle.c,
                v: data.candle.v,
                t: data.candle.timestamp,
            };
        }
    });

    channel.bind('post_block', (post_event) => {

        let data = JSON.parse(post_event.message);

        dataStore.value = {
            currentPrice: data.price_in_usd,
            currentPriceBase: data.price_in_base,
            tokenReserves: data.reserves?.token,
            baseReserves: data.reserves?.base,
            contractBalance: data.balances?.token,
            ownerBalance: data.balances?.owner,
            deployerBalance: data.balances?.deployer,
            five_min: data.performance.performance['5M'],
            one_hour: data.performance.performance['1H'],
            six_hour: data.performance.performance['6H'],
            twenty_four_hour: data.performance.performance['24H'],
        };
    });

    return channel;
};

const subscribeToBlockEventsChannel = () => {
    const blockDataChannel = pusher.subscribe('block_data');
    blockDataChannel.bind('block_data', (block_data_event) => {
        liveBlockData.value = (block_data_event.message);
    })

    return blockDataChannel;
}

const unsubscribeFromChannel = (channel) => {
    if (channel) {
        pusher.unsubscribe(channel.name);
    }
};

function getBaseAmount(swap, pair) {
    const transactionType = getTypeOfTransaction(swap.type);
    const tokenOrder = pair.token_order;

    if (transactionType === 'Buy') {
        return tokenOrder === 0
            ? swap.reserve_in / (10 ** pair.token1Decimals)
            : swap.reserve_in / (10 ** pair.token0Decimals);
    } else if (transactionType === 'Sell') {
        return tokenOrder === 0
            ? swap.reserve_out / (10 ** pair.token1Decimals)
            : swap.reserve_out / (10 ** pair.token0Decimals);
    } else {
        return tokenOrder === 0
            ? swap.reserve_out / (10 ** pair.token1Decimals)
            : swap.reserve_in / (10 ** pair.token0Decimals);
    }
}

function getTypeOfTransaction(type) {
    const typeMap = new Map([
        [0, 'Buy'],
        [1, 'Sell'],
        [2, 'ADD'],
        [3, 'REM']
    ]);

    return typeMap.get(type) || 'Unknown'; // Default to 'Unknown' if type not found
}

function getTokenAmount(swap, pair) {
    const transactionType = getTypeOfTransaction(swap.type);
    const tokenOrder = pair.token_order;

    if (transactionType === 'Buy') {
        return tokenOrder === 0
            ? swap.reserve_out / (10 ** pair.token0Decimals)
            : swap.reserve_out / (10 ** pair.token1Decimals);
    } else if (transactionType === 'Sell') {
        return tokenOrder === 0
            ? swap.reserve_in / (10 ** pair.token0Decimals)
            : swap.reserve_in / (10 ** pair.token1Decimals);
    } else {
        return tokenOrder === 0
            ? swap.reserve_in / (10 ** pair.token0Decimals)
            : swap.reserve_out / (10 ** pair.token1Decimals);
    }
}


const formatSwap = (swapData, pair) => {
    if (!swapData || typeof swapData !== 'object') {
        throw new Error("Invalid swap data");
    }
    let swap = swapData['swap'];
    let formattedData =
        {

            formatted_timestamp: swapData.block_timestamp,

            base_amount: getBaseAmount(swapData, pair) || 'N/A',
            block_number: swapData.block_number || 'N/A',
            id: swapData.swap_id != null ? swapData.swap_id : 'N/A',
            log_index: swapData.log_index || 'N/A',
            maker_address: swapData.maker_address || 'N/A',

            price_in_usd: swapData.price_in_usd,
            volume_in_usd: swapData.volume_in_usd,

            // token_order: swapData.token_order != null ? swapData.token_order : 'N/A',
            // token_0_symbol: swapData.token_0_symbol || 'N/A',
            // token_1_symbol: swapData.token_1_symbol || 'N/A',
            token_amount: getTokenAmount(swapData, pair) || 'N/A',
            total_price_of_swap: swapData.total_price_of_swap !== undefined
                ? `$${swapData.total_price_of_swap}`
                : 'N/A',
            transaction_index: swapData.transaction_index || 'N/A',
            // transaction_volume: swapData.transaction_volume !== undefined
            //     ? `$${swapData.transaction_volume}`
            //     : 'N/A',
            tx_hash: swapData.tx_hash || 'N/A',
            type: swapData.type !== undefined ? swapData.type : 'N/A'
        }
    return formattedData;
};

export { subscribeToSwapChannel, unsubscribeFromChannel, subscribeToBlockEventsChannel, swapData, dataStore, liveBlockData, candleData};
