export class CacheService {
  constructor() {
    this.cache = new Map();
  }

  /**
   * Set data in cache with metadata
   * @param {string} key - Cache key
   * @param {any} data - Data to cache
   * @param {Object} options - Cache options
   * @param {number} options.ttl - Time to live in milliseconds
   * @param {string} options.version - Data version for invalidation
   */
  set(key, data, options = { ttl: 5 * 60 * 1000, version: '1' }) {
    this.cache.set(key, {
      data,
      timestamp: Date.now(),
      ttl: options.ttl,
      version: options.version
    });
  }

  /**
   * Get data from cache if valid
   * @param {string} key - Cache key
   * @param {string} version - Required version
   * @returns {any|null} Cached data or null if invalid/expired
   */
  get(key, version = '1') {
    const cached = this.cache.get(key);
    if (!cached) return null;

    const isExpired = Date.now() - cached.timestamp > cached.ttl;
    const isOutdated = cached.version !== version;

    if (isExpired || isOutdated) {
      this.cache.delete(key);
      return null;
    }

    return cached.data;
  }

  /**
   * Update existing cache entry with new data
   * @param {string} key - Cache key
   * @param {any} data - New data
   */
  update(key, data) {
    const cached = this.cache.get(key);
    if (cached) {
      cached.data = data;
      cached.timestamp = Date.now();
      this.cache.set(key, cached);
    }
  }

  /**
   * Clear specific cache entry
   * @param {string} key - Cache key
   */
  invalidate(key) {
    this.cache.delete(key);
  }

  /**
   * Clear all cache entries
   */
  clear() {
    this.cache.clear();
  }
}

export const cacheService = new CacheService(); 