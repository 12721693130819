import { ref, reactive, onMounted } from 'vue';
import { getActiveChains, getChainById } from '@/config/chains';

const chainState = reactive({
  selectedChain: null,
  chainId: null,
  isConnected: false
});

export function useChain() {
  const activeChains = ref(getActiveChains());

  const selectChain = async (chain) => {
    if (!chain.active) return;
    
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chain.id.toString(16)}` }],
        });
        chainState.selectedChain = chain;
        chainState.chainId = chain.id;
        chainState.isConnected = true;
      }
    } catch (error) {
      console.error('Error switching chain:', error);
    }
  };

  const initializeChain = async () => {
    if (window.ethereum) {
      try {
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        const chain = getChainById(parseInt(chainId, 16));
        if (chain) {
          chainState.selectedChain = chain;
          chainState.chainId = chain.id;
          chainState.isConnected = true;
        }
      } catch (error) {
        console.error('Error initializing chain:', error);
      }
    }
  };

  onMounted(() => {
    initializeChain();

    if (window.ethereum) {
      window.ethereum.on('chainChanged', async (chainId) => {
        const chain = getChainById(parseInt(chainId, 16));
        if (chain) {
          chainState.selectedChain = chain;
          chainState.chainId = chain.id;
          chainState.isConnected = true;
        }
      });

      window.ethereum.on('disconnect', () => {
        chainState.selectedChain = null;
        chainState.chainId = null;
        chainState.isConnected = false;
      });
    }
  });

  return {
    chainState,
    activeChains,
    selectChain,
    initializeChain
  };
} 