<template>
  <div class="token-holdings-container">
    

    <div class="p-4">
  
      <div class="space-y-4">
        <div v-if="isLoading" class="text-center py-4">
          <ProgressSpinner />
        </div>
        
        <div v-else-if="tokenBalances.length === 0" class="text-center py-4 text-gray-400">
          No tokens found
        </div>

        <div v-else class="space-y-2">
          <div 
            v-for="token in tokenBalances" 
            :key="token.address"
            class=" rounded-lg p-4  transition-colors"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center space-x-3">
                <div class="w-8 h-8 rounded-full  flex items-center justify-center overflow-hidden">
                  <img 
                    :src="getTokenIcon(token.address, token.symbol)" 
                    :alt="token.symbol"
                    :data-address="token.address"
                    :data-symbol="token.symbol"
                    class="w-full h-full object-contain"
                    @error="handleImageError"
                    @load="handleImageSuccess"
                  />
                </div>
                <div>
                  <div class="font-semibold">{{ token.symbol }}</div>
                  <div class="text-sm text-gray-400">{{ token.name }}</div>
                </div>
              </div>
              <div class="text-right">
                <div class="font-semibold">{{ token.formatted || '0.00' }}</div>
                <div class="text-sm text-gray-400">${{ formatUsdValue(token.usdValue) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import { ethers } from 'ethers';
import { ERC20_ABI } from '@/abi/erc20';
import { getTokenIcon } from '@/utils/tokenIcons';

const props = defineProps({
  address: {
    type: String,
    required: true
  },
  tokenAddresses: {
    type: Array,
    default: () => []
  }
});

const ethBalance = ref(null);
const tokenBalances = ref([]);
const isLoading = ref(false);

const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      props.address = accounts[0];
      await updateBalances();
    } catch (error) {
      console.error('User rejected request:', error);
    }
  } else {
    alert('MetaMask is not installed.');
  }
};

const disconnectWallet = () => {
  props.address = null;
  ethBalance.value = null;
  tokenBalances.value = [];
};

const formatEthBalance = (balance) => {
  if (!balance) return '0.00';
  return ethers.formatEther(balance.toString());
};

const formatUsdValue = (value) => {
  if (!value) return '0.00';
  const numValue = typeof value === 'bigint' ? Number(value) : value;
  return numValue.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const handleImageError = () => {
  // Do nothing, just let the image fail
};

const handleImageSuccess = () => {
  // Do nothing, just let the image load
};

const getTokenBalances = async () => {
  if (!props.address) return;
  
  isLoading.value = true;
  try {
    console.log('[TokenHoldings] Fetching token balances for address:', props.address);
    const provider = new ethers.BrowserProvider(window.ethereum);
    
    // Get ETH balance
    const balance = await provider.getBalance(props.address);
    console.log('[TokenHoldings] Raw ETH balance:', balance);
    ethBalance.value = balance.toString();
    
    // Get token balances
    console.log('[TokenHoldings] Fetching balances for tokens:', props.tokenAddresses);
    const balances = await Promise.all(
      props.tokenAddresses.map(async (address) => {
        const contract = new ethers.Contract(address, ERC20_ABI, provider);
        const [balance, decimals, symbol, name] = await Promise.all([
          contract.balanceOf(props.address),
          contract.decimals(),
          contract.symbol(),
          contract.name()
        ]);
        
        return {
          address,
          name,
          symbol,
          balance: balance.toString(),
          decimals,
          formatted: ethers.formatUnits(balance, decimals),
          usdValue: 0 // You would need to fetch price data to calculate this
        };
      })
    );

    tokenBalances.value = balances;
    console.log('[TokenHoldings] Updated token balances:', tokenBalances.value);
  } catch (error) {
    console.error('[TokenHoldings] Error fetching token balances:', error);
  } finally {
    isLoading.value = false;
  }
};

const updateBalances = async () => {
  await getTokenBalances();
};

watch(() => props.address, getTokenBalances);
watch(() => props.tokenAddresses, getTokenBalances);

onMounted(() => {
  if (props.address) {
    getTokenBalances();
  }
});
</script>

<style scoped>

</style> 