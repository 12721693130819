<template>
  <div v-if="!isLoading" class="flex items-center justify-center">
    <div class="flex items-center space-x-4">
      <div class="flex items-center">
        <i class="fa-brands fa-ethereum "></i>
        <span class=" ml-1">
          <i class="pi pi-ethereum" ></i>

          $2,503.13
        </span>
      </div>

      <div class="flex flex-col items-center">
        <button
            class="flex items-center space-x-1 hover:text-gray-200"
            @mouseenter="gas_hovered = true"
            @mouseleave="gas_hovered = false"
        >
          <font-awesome-icon :icon="['fa', 'gas-pump']" />

          <span >{{ format.toSignificantFigures((blockData.gas_price_avg)/(10**9), 3) }} gwei</span>
        </button>
        <div
            v-show="gas_hovered"
            class="absolute top-full left-1/2 transform -translate-x-1/2 bg-gray-800 p-2 rounded-md shadow-md"
        >
          <div class="space-y-1">
            <div class="flex justify-between">
              <span class="text-xs font-semibold">ETH TRANSFER:</span>
              <span class="text-xs font-semibold">$2.47</span>
            </div>
            <div class="flex justify-between">
              <span class="text-xs font-semibold">ERC20 TRANSFER:</span>
              <span class="text-xs font-semibold">$2.47</span>
            </div>
            <div class="flex justify-between">
              <span class="text-xs font-semibold">DEX SWAP:</span>
              <span class="text-xs font-semibold">$14.47</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <button class="flex items-center space-x-1 hover:text-gray-200 mr-1.5">
          <i class="pi pi-box"></i>
        </button>
        <ProgressBar :value="progress" class="w-40 h-0.5" > <span class="block">{{ blockData.block_number }}</span> </ProgressBar>
        <div
            v-show="block_hovered"
            class="absolute top-full left-1/2 transform -translate-x-1/2 bg-gray-800 p-2 rounded-md shadow-md"
        >
          <div class="space-y-1">
            <div class="flex justify-between">
              <span class="text-xs font-semibold">CURRENT BLOCK:</span>
              <span class="text-xs font-semibold">{{ blockData.block_number }}</span>
            </div>
            <div class="flex justify-between">
              <span class="text-xs font-semibold">TX COUNT:</span>
              <span class="text-xs font-semibold">{{ blockData.block_tx_count }}</span>
            </div>
            <div class="flex justify-between">
              <span class="text-xs font-semibold">GAS USAGE:</span>
              <span class="text-xs font-semibold">{{ blockData.block_gas_used }}</span>
              <span class="text-xs font-semibold">{{ blockData.block_gas_limit }}</span>
            </div>
            <ProgressBar :value="gasUsageWidth" class="w-full h-2 mt-1" />
            <div class="flex justify-between mt-1">
              <span class="text-xs font-semibold">BURNT FEES:</span>
              <span class="text-xs font-semibold">
                {{ formattedBurntGasFees }} ETH ($656.50)
              </span>
            </div>
            <div class="flex justify-between">
              <span class="text-xs font-semibold">MINER REV:</span>
              <span class="text-xs font-semibold">
                {{ formattedMinerFees }} ETH ($226.20)
              </span>
            </div>
            <div class="flex justify-between">
              <span class="text-xs font-semibold">BASE:</span>
              <span class="text-xs font-semibold">{{ baseFee }} gwei</span>
            </div>
            <div class="flex justify-between">
              <span class="text-xs font-semibold">AVG:</span>
              <span class="text-xs font-semibold">{{ averageGasPrice }} gwei</span>
            </div>
            <div class="flex justify-between">
              <span class="text-xs font-semibold">HIGH:</span>
              <span class="text-xs font-semibold">{{ highGasPrice }} gwei</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {ref, computed, onMounted, onUnmounted, watch} from 'vue';
import * as Api from "@/services/Api";
import format from "@/helpers/format.js"
import {liveBlockData, subscribeToBlockEventsChannel} from '@/echo';
import ProgressBar from 'primevue/progressbar';

export default {
  name: 'chainInfo',
  async beforeMount() {
    const channel = subscribeToBlockEventsChannel();
  },
  setup() {
    const gas_hovered = ref(false);
    const block_hovered = ref(false);

    const isLoading = ref(true);

    const blockData = ref({
      block_base_fee: 0,
      block_gas_limit: 0,
      block_gas_used: 0,
      block_number: 0,
      block_timestamp: 0,
      block_tx_count: 0,
      gas_price_avg: 0,
      gas_price_high: 0,
      gas_price_low: 0,
      gas_total_spent: 0,
      predicted_next_block_timestamp: 0,
    });

    const currentTime = ref(Math.floor(Date.now() / 1000)); // Holds the current time in UNIX timestamp (seconds)
    let targetTime = ref(currentTime.value + 12);           // Holds the target time, initially 12 seconds from now

    const progress = computed(() => {
      const startTime = blockData.value.block_timestamp; // Start time of the block
      const endTime = blockData.value.predicted_next_block_timestamp; // Predicted next block timestamp
      const current = currentTime.value;

      if (endTime > startTime) {
        return Math.min(((current - startTime) / (endTime - startTime)) * 100, 100);
      }
      return 0; // If endTime is not greater than startTime, progress is 0
    });

    const secondsLeft = computed(() => {
      const endTime = blockData.value.predicted_next_block_timestamp; // Predicted next block timestamp
      return Math.max(endTime - currentTime.value, 0); // Ensure non-negative countdown
    });

    const updateCurrentTime = () => {
      currentTime.value = Math.floor(Date.now() / 1000);  // Update current time to current UNIX timestamp (seconds)
    };

    const gasUsageWidth = computed(() => {
      return (blockData.value.block_gas_used / blockData.value.block_gas_limit) * 100;
    });

    const formattedBurntGasFees = computed(() => {
      return format.toSignificantFigures(((blockData.value.block_base_fee * blockData.value.block_gas_used) / (10**18)), 3);
    })

    const formattedMinerFees = computed(() => {
      const avgPriority = blockData.value.gas_price_avg - blockData.value.block_base_fee
      return format.toSignificantFigures(((avgPriority * blockData.value.block_gas_used) / (10**18)), 3);
    })

    const baseFee = computed(() => {
      return format.toSignificantFigures(blockData.value.block_base_fee / (10**9), 3);
    });

    const averageGasPrice = computed(() => {
      return format.toSignificantFigures(blockData.value.gas_price_avg / (10**9), 3);
    });

    const highGasPrice = computed(() => {
      return format.toSignificantFigures(blockData.value.gas_price_high / (10**9), 3);
    });

    const latest_block_data = async () => {
      try {
        const response = await Api.apiClient.get(`/chain/latest_block`);
        blockData.value = response.data;
        isLoading.value = false;
      } catch(error) {
        console.log(error);
        isLoading.value = false;
      }
    }

    let interval;
    onMounted(() => {
      interval = setInterval(updateCurrentTime, 1000); // Update every second
      latest_block_data()
      // console.log(blockData.predicted_next_block_timestamp);
      targetTime.value = blockData.value.predicted_next_block_timestamp;
      watch(liveBlockData, (newLiveBlockData) => {
        blockData.value = {...newLiveBlockData};
      }, { deep: true });
    });

    onUnmounted(() => {
      clearInterval(interval);  // Cleanup the timer on unmount
    });

    return {
      format,
      formattedBurntGasFees,
      formattedMinerFees,
      blockData,
      gas_hovered,
      block_hovered,
      progress,
      currentTime,
      targetTime,
      secondsLeft,
      gasUsageWidth,
      isLoading,
      baseFee,
      averageGasPrice,
      highGasPrice
    };
  }

};
</script>

<style scoped>

#gas-tooltip-content {
  width: auto;
  background-color: rgb(60, 60, 60);
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  margin-left: -2.5%;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
}

.gas-tooltip-content:after {
  content:'';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 25px solid #6A0136;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}


</style>