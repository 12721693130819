import { ref, computed, onMounted } from 'vue';
import { getEthBalance } from '@/utils/ethereum';

const connectedAddress = ref(null);
const balance = ref(null);

export function useWallet() {
  const isConnected = computed(() => !!connectedAddress.value);

  const updateBalance = async () => {
    if (!connectedAddress.value) return;
    balance.value = await getEthBalance(connectedAddress.value);
  };

  const setAddress = (address) => {
    connectedAddress.value = address;
    if (address) {
      updateBalance();
    } else {
      balance.value = null;
    }
  };

  onMounted(() => {
    // Check for existing MetaMask connection
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_accounts' })
        .then(accounts => {
          if (accounts.length > 0) {
            setAddress(accounts[0]);
          }
        })
        .catch(error => {
          console.error('Error checking MetaMask connection:', error);
        });

      // Listen for account changes
      window.ethereum.on('accountsChanged', (accounts) => {
        setAddress(accounts[0] || null);
      });

      // Listen for disconnect event
      window.ethereum.on('disconnect', () => {
        setAddress(null);
      });
    }
  });

  return {
    connectedAddress,
    balance,
    isConnected,
    setAddress,
    updateBalance
  };
} 