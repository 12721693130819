<script setup>
import { useLayout } from "@/layout/composables/layout";
import { computed, ref, watch } from "vue";
import AppFooter from "./AppFooter.vue";
import AppSidebar from "./AppSidebar.vue";
import AppTopbar from "./AppTopbar.vue";
import MobileMenu from "./MobileMenu.vue";
import AuthenticatedLayout from "./AuthenticatedLayout.vue";

const { layoutConfig, layoutState, isSidebarActive } = useLayout();
const outsideClickListener = ref(null);

watch(isSidebarActive, (newVal) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

const containerClass = computed(() => ({
    "layout-overlay": layoutConfig.menuMode === "overlay",
    "layout-static": layoutConfig.menuMode === "static",
    "layout-static-inactive": layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === "static",
    "layout-overlay-active": layoutState.overlayMenuActive,
    "layout-mobile-active": layoutState.staticMenuMobileActive,
}));

function bindOutsideClickListener() {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                layoutState.overlayMenuActive = false;
                layoutState.staticMenuMobileActive = false;
                layoutState.menuHoverActive = false;
            }
        };
        document.addEventListener("click", outsideClickListener.value);
    }
}

function unbindOutsideClickListener() {
    if (outsideClickListener.value) {
        document.removeEventListener("click", outsideClickListener);
        outsideClickListener.value = null;
    }
}

function isOutsideClicked(event) {
    const sidebarEl = document.querySelector(".layout-sidebar");
    const topbarEl = document.querySelector(".layout-menu-button");

    return !(sidebarEl.contains(event.target) || topbarEl.contains(event.target));
}
</script>

<template>
    <div class="layout-wrapper" :class="containerClass">
        <app-sidebar class="layout-sidebar"></app-sidebar>
        <div class="layout-main-container">
            <app-topbar class="app-topbar"></app-topbar>
            <div class="layout-main">
                <div class="h-full">
                    <router-view></router-view>
                </div>
            </div>
            <app-footer class="app-footer mb-5"></app-footer>
        </div>
        <mobile-menu></mobile-menu>
    </div>
</template>

<style scoped>
.layout-wrapper {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.layout-main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
}

.app-topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px; 
    background: var(--primary3);
    backdrop-filter: blur(10px); 
    z-index: 1000;
}

.layout-main {
    margin-top: 25px;
    height: calc(100vh - 56px); 
    overflow-y: auto; 
    background-color: var(--primary4); 
    padding: 1rem; 
}

@media (max-width: 991px) {
    .layout-sidebar {
        display: none;
    }

    .layout-main {
        padding-bottom: 60px; /* Add padding for mobile menu */
    }
}
</style>