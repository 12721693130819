export const chains = {
  ethereum: {
    id: 1,
    name: 'Ethereum',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png',
    active: true
  },
  polygon: {
    id: 137,
    name: 'Polygon',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/info/logo.png',
    active: true
  },
  arbitrum: {
    id: 42161,
    name: 'Arbitrum',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/arbitrum/info/logo.png',
    active: true
  },
  optimism: {
    id: 10,
    name: 'Optimism',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/optimism/info/logo.png',
    active: true
  },
  base: {
    id: 8453,
    name: 'Base',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/base/info/logo.png',
    active: true
  }
};

export const getActiveChains = () => {
  return Object.entries(chains)
    .filter(([_, chain]) => chain.active)
    .map(([key, chain]) => ({ key, ...chain }));
};

export const getChainById = (chainId) => {
  return Object.entries(chains).find(([_, chain]) => chain.id === parseInt(chainId))?.[1];
}; 