<template>
  <div class="chain-select">
    <Button
      class="chain-button"
      @click="togglePopover"
      :class="{ 'p-button-outlined': !chainState.selectedChain }"
    >
      <img
        v-if="chainState.selectedChain"
        :src="chainState.selectedChain.icon"
        :alt="chainState.selectedChain.name"
        class="chain-icon"
      />
      <i v-else class="pi pi-link" style="font-size: 1.2rem"></i>
    </Button>

    <OverlayPanel ref="op" class="chain-popover">
      <div class="chain-list">
        <div
          v-for="chain in activeChains"
          :key="chain.id"
          class="chain-item"
          :class="{ 'chain-item-disabled': !chain.active }"
          @click="handleChainSelect(chain)"
        >
          <img :src="chain.icon" :alt="chain.name" class="chain-icon" />
          <span class="chain-name">{{ chain.name }}</span>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useChain } from '@/layout/composables/useChain';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';

const op = ref(null);
const { chainState, activeChains, selectChain } = useChain();

const togglePopover = (event) => {
  op.value.toggle(event);
};

const handleChainSelect = async (chain) => {
  await selectChain(chain);
  op.value.hide();
};
</script>

<style scoped>
.chain-select {
  position: relative;
}

.chain-button {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 1px solid var(--surface-border);
  cursor: pointer;
  transition: all 0.2s ease;
}

.chain-button:hover {
  background: var(--surface-card);
}

.chain-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.chain-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
}

.chain-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.chain-item:hover {
  background: var(--surface-overlay);
}

.chain-item-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.chain-item-disabled:hover {
  background: transparent;
}

.chain-name {
  font-size: 0.875rem;
  color: var(--text-color);
}

:deep(.p-overlaypanel) {
  background: var(--surface-overlay);
  border: 1px solid var(--surface-border);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>