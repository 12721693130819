<script setup>
import AppMenu from './AppMenu.vue';
import TokenWatchlist from '../components/TokenWatchlist.vue';
import { computed } from 'vue';
import { useMockData } from '@/composables/useMockData';
import ChainSelect from '../components/ChainSelect.vue';

const isDevelopment = computed(() => import.meta.env.DEV);
const { currentBlock, isSimulating, startSimulation, stopSimulation } = useMockData();
</script>

<template>
    <div class="layout-sidebar">
        <div class="scrollable-content">
            <div class="menu-container mb-5">
                <app-menu></app-menu>
            </div>
            <div v-if="isDevelopment" >
                <div >
                    Block: {{ currentBlock }}
                </div>
                <button 
                    @click="isSimulating ? stopSimulation() : startSimulation()"
                    :class="['simulation-button', { 'active': isSimulating }]"
                >
                    {{ isSimulating ? 'Stop Simulation' : 'Start Simulation' }}
                </button>
            </div>
            <token-watchlist class="sidebar-watchlist"></token-watchlist>
            <div class="chain-selector-container">
            <ChainSelect></ChainSelect>
        </div>
        </div>
       
    </div>
</template>

<style lang="scss" scoped>
.layout-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--primary3);
}

.scrollable-content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.menu-container {
    flex: 0 0 auto;
    min-height: 0;
}

.mock-controls {
    padding: 1rem;
    border-top: 1px solid var(--surface-border);
    border-bottom: 1px solid var(--surface-border);
    background: var(--primary3);
}

.block-display {
    color: var(--text-color);
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    text-align: center;
}

.simulation-button {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--surface-border);
    border-radius: 6px;
    background: var(--primary2);
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.2s;
    font-size: 0.875rem;

    &:hover {
        background: var(--primary1);
    }

    &.active {
        background: var(--primary1);
        border-color: var(--primary-color);
    }
}

.sidebar-watchlist {
    flex: 0 0 auto; 
    height: 20vh; 
    border-top: 1px solid var(--surface-border);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.chain-selector-container {
    height: 20vh; 
    flex: 0 0 auto;
    padding: 1rem;
    border-top: 1px solid var(--surface-border);
    background: var(--primary3);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
