import { apiService } from './Api';

class MockDataService {
  constructor() {
    this.mockIntervals = new Map();
    this.currentBlock = 18990000;
    this.blockTime = 12; // seconds
    this.mockPairs = []; // Store mock pairs locally
    this.shouldGenerateNewPairs = false;
    this.activePairAddress = null; // Track the currently active pair
    this.activePairData = null; // Store the active pair's full data
  }

  // Generate a random token name and symbol
  generateTokenData() {
    const prefixes = ['PRIME', 'MOON', 'PEPE', 'SAFE', 'DOGE', 'SHIB', 'BABY'];
    const suffixes = ['INU', 'ROCKET', 'ELON', 'FLOKI', 'AI', 'ETH', 'SWAP'];
    const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
    const suffix = suffixes[Math.floor(Math.random() * suffixes.length)];
    const symbol = `${prefix}${suffix}`;
    return {
      symbol,
      name: `${symbol} Token`,
      address: '0x' + Math.random().toString(16).substr(2, 40)
    };
  }

  // Generate a mock pair
  generateMockPair() {
    const token = this.generateTokenData();
    const initialPrice = Math.random() * 0.0001;
    
    return {
      pair_id: Math.floor(Math.random() * 100000),
      pair_address: '0x' + Math.random().toString(16).substr(2, 40),
      token: {
        ...token,
        price: initialPrice
      },
      base: {
        symbol: 'ETH',
        name: 'Ethereum',
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
      },
      price: initialPrice,
      performance: {
        '5M': 0,
        '1H': 0,
        '6H': 0,
        '24H': 0
      },
      reserves: {
        value: Math.random() * 100000
      },
      buy_count: 0,
      sell_count: 0,
      time: Math.floor(Date.now() / 1000)
    };
  }

  // Generate a mock swap
  generateMockSwap(pair, isBuy = Math.random() > 0.5) {
    const amount = Math.random() * 5;
    const currentPrice = pair.NAME?.price || pair.price;
    const price = currentPrice * (1 + (Math.random() * 0.1 - 0.05));
    
    return {
      transaction_hash: '0x' + Math.random().toString(16).substr(2, 64),
      amount0: isBuy ? amount : amount * price,
      amount1: isBuy ? amount * price : amount,
      timestamp: Math.floor(Date.now() / 1000),
      maker: '0x' + Math.random().toString(16).substr(2, 40),
      type: isBuy ? 0 : 1, // 0 for buy, 1 for sell
      price_in_usd: price,
      volume_in_usd: amount * price,
      token_amount: amount,
      base_amount: amount * price,
      maker_address: '0x' + Math.random().toString(16).substr(2, 40),
      tx_hash: '0x' + Math.random().toString(16).substr(2, 64),
      formatted_timestamp: Math.floor(Date.now() / 1000)
    };
  }

  // Generate mock chart data
  generateMockChartData(pair) {
    const timestamp = Math.floor(Date.now() / 1000);
    const price = pair.price * (1 + (Math.random() * 0.1 - 0.05));
    const volume = Math.random() * 1000;
    
    return {
      t: timestamp,
      o: price,
      h: price * 1.001,
      l: price * 0.999,
      c: price,
      v: volume
    };
  }

  // Start simulating new pairs
  startNewPairSimulation() {
    this.shouldGenerateNewPairs = true;
    
    // Generate initial pairs if none exist
    if (this.mockPairs.length === 0) {
      // Generate only 3 initial pairs instead of 10
      for (let i = 0; i < 3; i++) {
        const newPair = this.generateMockPair();
        this.mockPairs.push(newPair);
        // Start swap simulation for each new pair
        this.startSwapSimulation(newPair);
        console.log(`Initial pair ${i + 1} created and swap simulation started for:`, newPair.token.symbol);
      }
      
      // Format initial data to match API response structure
      const response = {
        data: {
          data: this.mockPairs,
          meta: {
            current_page: 1,
            total: this.mockPairs.length
          }
        }
      };

      // Only update with mock pairs, don't replace existing data
      apiService.handleLiveUpdate('/mock-pairs', response.data);
    }
  }

  // Set the active pair for swap simulation
  setActivePair(pairAddress, pairData) {
    console.log('Setting active pair:', pairAddress, 'with data:', pairData);
    this.activePairAddress = pairAddress;
    this.activePairData = pairData;
    
    // Start swap simulation for this pair
    this.startSwapSimulation(pairData);
  }

  // Clear active pair
  clearActivePair() {
    console.log('Clearing active pair');
    if (this.activePairAddress) {
      // Stop swap simulation for the current pair
      this.stopSimulation(`swaps_${this.activePairAddress}`);
      this.activePairAddress = null;
    }
  }

  // Start simulating swaps for a specific pair
  startSwapSimulation(pair, interval = 5000) {
    // Stop any existing simulation for this pair
    this.stopSimulation(`swaps_${pair.pair_address}`);
    
    console.log('Starting swap simulation for pair:', pair.pair_address, pair.NAME?.nonBaseToken || pair.token?.symbol);
    
    const simulateSwap = () => {
      // Only generate swaps if this is the active pair
      if (this.activePairAddress !== pair.pair_address) {
        console.log('Skipping swap simulation - pair is not active:', pair.NAME?.nonBaseToken || pair.token?.symbol);
        return;
      }

      const swap = this.generateMockSwap(pair);
      console.log(`Generated new mock swap for ${pair.NAME?.nonBaseToken || pair.token?.symbol}:`, swap);
      const updatedPair = { ...pair };
      
      // Update pair data based on swap
      if (swap.type === 0) { // Buy
        updatedPair.buy_count = (updatedPair.buy_count || 0) + 1;
      } else { // Sell
        updatedPair.sell_count = (updatedPair.sell_count || 0) + 1;
      }

      // Update price and performance
      const currentPrice = pair.NAME?.price || pair.price;
      updatedPair.NAME = {
        ...updatedPair.NAME,
        price: swap.price_in_usd
      };
      updatedPair.price = swap.price_in_usd;
      
      const priceChange = ((swap.price_in_usd - currentPrice) / currentPrice) * 100;
      updatedPair.performance = {
        ...updatedPair.performance,
        '5M': priceChange
      };

      // Update pair data
      const pairResponse = {
        data: {
          data: updatedPair,
          meta: {
            current_page: 1,
            total: 1
          }
        }
      };
      apiService.handleLiveUpdate(`/pairs/${pair.pair_address}`, pairResponse.data);

      // Update mock swaps - Simplified response structure
      const swapResponse = {
        data: swap
      };
      console.log(`Sending mock swap update for ${pair.NAME?.nonBaseToken || pair.token?.symbol}:`, swapResponse);
      apiService.handleLiveUpdate(`/mock-swaps/${pair.pair_address}`, swapResponse);

      // Generate and update chart data
      const chartData = this.generateMockChartData(updatedPair);
      const chartResponse = {
        data: chartData
      };
      apiService.handleLiveUpdate(`/chart/${pair.pair_address}`, chartResponse.data);

      // Store chart data in localStorage
      const storedChartData = localStorage.getItem(`mockChartData_${pair.pair_address}`);
      let chartDataArray = storedChartData ? JSON.parse(storedChartData) : [];
      chartDataArray.push(chartData);
      if (chartDataArray.length > 1000) {
        chartDataArray = chartDataArray.slice(-1000);
      }
      localStorage.setItem(`mockChartData_${pair.pair_address}`, JSON.stringify(chartDataArray));

      console.log(`Swap simulation completed for ${pair.NAME?.nonBaseToken || pair.token?.symbol}`);
    };

    // Run immediately and then set interval
    simulateSwap();
    this.mockIntervals.set(`swaps_${pair.pair_address}`, setInterval(simulateSwap, interval));
  }

  // Start simulating block timer
  startBlockSimulation(interval = 12000) {
    const simulateBlock = () => {
      this.currentBlock++;

      // Generate new pair with each block if enabled
      if (this.shouldGenerateNewPairs && this.mockPairs.length < 5) { // Limit to 5 pairs total
        const newPair = this.generateMockPair();
        this.mockPairs.unshift(newPair);
        
        // Keep only the last 5 pairs
        if (this.mockPairs.length > 5) {
          this.mockPairs = this.mockPairs.slice(0, 5);
        }

        // Start swap simulation for the new pair
        this.startSwapSimulation(newPair);

        // Format data to match API response structure
        const response = {
          data: {
            data: this.mockPairs,
            meta: {
              current_page: 1,
              total: this.mockPairs.length
            }
          }
        };

        // Only update with mock pairs, don't replace existing data
        apiService.handleLiveUpdate('/mock-pairs', response.data);
        console.log('New pair added and swap simulation started for:', newPair.token.symbol);
      }

      const event = new CustomEvent('blockUpdate', {
        detail: {
          blockNumber: this.currentBlock,
          timestamp: Math.floor(Date.now() / 1000)
        }
      });
      window.dispatchEvent(event);
      console.log('Block updated:', this.currentBlock);
    };

    // Initial dispatch
    simulateBlock();
    this.mockIntervals.set('blocks', setInterval(simulateBlock, interval));
  }

  // Stop all simulations
  stopAllSimulations() {
    this.shouldGenerateNewPairs = false;
    for (const interval of this.mockIntervals.values()) {
      clearInterval(interval);
    }
    this.mockIntervals.clear();
  }

  // Stop specific simulation
  stopSimulation(key) {
    const interval = this.mockIntervals.get(key);
    if (interval) {
      clearInterval(interval);
      this.mockIntervals.delete(key);
    }
  }
}

export const mockDataService = new MockDataService(); 