<template>
  <wallet-connect-popover/>
</template>

<script setup>
import { ref } from "vue";
import { Button } from "primevue"; 
import { connectWallet, disconnectWallet } from "@/walletConnect/provider"; 
import WalletConnectPopoverVue from "./WalletConnectPopover.vue";
const address = ref(null);

async function connect() {
  try {
    const wallet = await connectWallet();
    if (wallet) {
      address.value = wallet.address;
    }
  } catch (error) {
    console.error("Failed to connect wallet:", error);
  }
}

async function disconnect() {
  try {
    await disconnectWallet();
    address.value = null;
  } catch (error) {
    console.error("Failed to disconnect wallet:", error);
  }
}
</script>
