import axios from "axios";
import store from "@/store";
import { cacheService } from './CacheService';

const baseURL =
    import.meta.env.NODE_ENV === "production"
        ? import.meta.env.VITE_API_URL
        : import.meta.env.VITE_API_URL_DEV;

export const apiClient = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.getters["auth/authUser"] &&
            !store.getters["auth/guest"]
        ) {
            store.dispatch("auth/logout");
        }
        return Promise.reject(error);
    }
);

export class ApiService {
    constructor() {
        this.version = '1';
        this.liveUpdateCallbacks = new Map();
    }

    /**
     * Generate cache key from request parameters
     */
    generateCacheKey(endpoint, params = {}) {
        return `${endpoint}:${JSON.stringify(params)}`;
    }

    /**
     * Fetch data with caching support
     */
    async fetchWithCache(endpoint, params = {}, options = {}) {
        const cacheKey = this.generateCacheKey(endpoint, params);
        const cachedData = cacheService.get(cacheKey, this.version);

        if (cachedData && !options.forceRefresh) {
            return cachedData;
        }

        try {
            const response = await apiClient.get(endpoint, { params });
            cacheService.set(cacheKey, response.data, {
                ttl: options.ttl || 5 * 60 * 1000,
                version: this.version
            });
            return response.data;
        } catch (error) {
            if (cachedData) {
                return cachedData; // Fallback to cached data on error
            }
            throw error;
        }
    }

    /**
     * Register callback for live updates
     */
    registerLiveUpdate(endpoint, callback) {
        if (!this.liveUpdateCallbacks.has(endpoint)) {
            this.liveUpdateCallbacks.set(endpoint, new Set());
        }
        this.liveUpdateCallbacks.get(endpoint).add(callback);
    }

    /**
     * Unregister callback for live updates
     */
    unregisterLiveUpdate(endpoint, callback) {
        const callbacks = this.liveUpdateCallbacks.get(endpoint);
        if (callbacks) {
            callbacks.delete(callback);
        }
    }

    /**
     * Handle live update data
     */
    handleLiveUpdate(endpoint, data) {
        const cacheKey = this.generateCacheKey(endpoint);
        cacheService.update(cacheKey, data);

        const callbacks = this.liveUpdateCallbacks.get(endpoint);
        if (callbacks) {
            callbacks.forEach(callback => callback(data));
        }
    }

    /**
     * Invalidate cache for specific endpoint
     */
    invalidateCache(endpoint) {
        const cacheKey = this.generateCacheKey(endpoint);
        cacheService.invalidate(cacheKey);
    }
}

export const apiService = new ApiService();
