<script setup>
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';
import SingleTicker from '@/components/tradingView/SingleTicker.vue'

const model = ref([
    {
        label: 'Block Compass',
        items: [
            { label: 'Home', icon: 'pi pi-fw pi-home', to: '/' },
            { label: 'Pairs', icon: 'pi pi-fw pi-ethereum', to: '/pairs' },
            { label: 'Trending', icon: 'pi pi-fw pi-chart-line', to: '/trending' },
        ]
    },
    {
        label: 'Socials',
        items: [
            { label: 'Discord', icon: 'pi pi-fw pi-discord', to: '/' },
            { label: 'Telegram', icon: 'pi pi-fw pi-telegram', to: '/pairs', separator: true },
        ]
    },
    {
        label: 'TradingView',
        items: [
            { label: 'Info', to: '/tradingview', separator: true },
            { 
                label: 'Saved Charts', 
                to: '/charts'
            },
        ]
    },
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="i">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped>
:deep(.p-badge) {
  background: var(--primary-color);
  color: var(--primary-color-text);
  font-size: 0.55rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  padding: 0 0.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
