<template>
  <div class="flex items-center">
    <Button 
      @click="toggleWalletInfo"
      text
      class="p-0 hover:bg-gray-50 rounded-lg transition-colors"
    >
      <div class="flex items-center space-x-2 px-3 py-1">
        <span class="text-xs font-medium">
          {{ truncateAddress(address) }}
        </span>
        <i class="pi pi-chevron-down text-xs"></i>
      </div>
    </Button>
    
    <OverlayPanel ref="walletInfoPopover" class="w-[280px]">
      <div class="p-3 space-y-3">
        <!-- ETH  -->
        <div class="flex flex-col space-y-1">
          <span class="text-xs text-gray-500">ETH Balance</span>
          <div class="flex items-center space-x-1 text-sm">
            <i class="pi pi-ethereum text-purple-600"></i>
            <span class="font-medium">{{ formatEthBalance(balance) }}</span>
          </div>
        </div>

        <!-- Token Holdings -->
        <TokenHoldings 
          :address="address"
          :token-addresses="tokenAddresses"
        />

        <div class="border-t border-gray-100"></div>
        <Button 
          icon="pi pi-power-off" 
          label="Disconnect"
          severity="secondary" 
          text 
          size="small"
          class="w-full justify-start"
          @click="handleDisconnect"
        />
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, watch } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
import { truncateAddress, formatEthBalance, getTokenBalances } from '@/utils/ethereum';
import TokenHoldings from './TokenHoldings.vue';

const props = defineProps({
  address: {
    type: String,
    required: true
  },
  balance: {
    type: String,
    required: true
  },
  tokenAddresses: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['disconnect']);
const walletInfoPopover = ref(null);
const tokenBalances = ref([]);

const updateTokenBalances = async () => {
  if (props.address && props.tokenAddresses.length > 0) {
    tokenBalances.value = await getTokenBalances(props.tokenAddresses, props.address);
  }
};

const toggleWalletInfo = (event) => {
  walletInfoPopover.value.toggle(event);
  if (!walletInfoPopover.value.visible) {
    updateTokenBalances();
  }
};

const handleDisconnect = () => {
  emit('disconnect');
  walletInfoPopover.value.hide();
};

watch(() => props.address, updateTokenBalances);

onMounted(updateTokenBalances);
</script>

<style scoped>
:deep(.p-overlaypanel-content) {
  padding: 0 !important;
}
</style> 