<template>
  <div class="flex flex-col items-center justify-center h-screen">
    <div v-if="connectedAddress">
      <WalletInfoPopover 
        :address="connectedAddress"
        :balance="balance"
        :token-addresses="TOKEN_ADDRESSES"
        @disconnect="disconnect"
      />
    </div>
    <Button v-else label="Connect Wallet" icon="pi pi-wallet" @click="togglePopover" size="small" />
    
    <Popover ref="popover">
      <div class="p-2">
        <div class="wallet-option flex items-center space-x-2 p-1 cursor-pointer hover:bg-gray-100 rounded-md" @click="walletConnect()">
          <Image :src="walletConnectIcon" alt="WalletConnect" width="24" />
          <span class="text-sm font-medium">WalletConnect</span>
        </div>
        <div 
          class="wallet-option flex items-center space-x-2 p-1 rounded-md" 
          :class="{ 'cursor-pointer hover:bg-gray-100': isMetaMaskAvailable, 'opacity-50 cursor-not-allowed': !isMetaMaskAvailable }"
          @click="isMetaMaskAvailable ? metaMaskConnect() : alert('MetaMask is not installed.')"
        >
          <Image :src="metaMaskIcon" alt="MetaMask" width="24" />
          <span class="text-sm font-medium">MetaMask</span>
        </div>
      </div>
    </Popover>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Popover from 'primevue/popover';
import Button from 'primevue/button';
import Image from 'primevue/image';
import { connectWallet, disconnectWallet, isWalletConnected, getWalletProvider } from '@/walletConnect/provider';
import walletConnectIcon from '@/assets/walletconnect.svg';
import metaMaskIcon from '@/assets/metamask.svg';
import { getEthBalance } from '@/utils/ethereum';
import WalletInfoPopover from './WalletInfoPopover.vue';
import { useWallet } from '@/composables/useWallet';

// Example token addresses - replace with your actual token addresses
const TOKEN_ADDRESSES = [
  '0x2f0eaDa3c6426283f1012d3dCF928c6a7DDde0Be', // USDT
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', // WBTC
  '0x514910771af9ca656af840dff83e8264ecf986ca'  // LINK
];

const { connectedAddress, balance, setAddress, updateBalance } = useWallet();
const popover = ref(null);
const isMetaMaskAvailable = ref(false);

const togglePopover = (event) => {
  popover.value.toggle(event);
};

const metaMaskConnect = async () => {
  if (window.ethereum) {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setAddress(accounts[0]);
      popover.value.hide();
    } catch (error) {
      console.error('User rejected request:', error);
    }
  } else {
    alert('MetaMask is not installed.');
  }
};

const walletConnect = async () => {
  try {
    const connection = await connectWallet();
    if (connection) {
      setAddress(connection.address);
      popover.value.hide();
    }
  } catch (error) {
    console.error('Wallet Connection Error:', error);
    // You might want to show an error message to the user here
  }
};

const disconnect = async () => {
  try {
    if (window.ethereum && connectedAddress.value) {
      // For MetaMask, we don't actually disconnect, just clear our state
      setAddress(null);
    } else {
      // For Reown Kit
      await disconnectWallet();
      setAddress(null);
    }
  } catch (error) {
    console.error('Error disconnecting:', error);
    // You might want to show an error message to the user here
  }
};

onMounted(async () => {
  isMetaMaskAvailable.value = typeof window.ethereum !== 'undefined';
  
  // Check for existing Reown Kit connection
  if (isWalletConnected()) {
    const provider = getWalletProvider();
    const connection = await provider.getConnection();
    setAddress(connection.address);
  }
  
  // Listen for account changes
  if (window.ethereum) {
    window.ethereum.on('accountsChanged', async (accounts) => {
      setAddress(accounts[0] || null);
    });
  }
});
</script>

<style scoped>
.wallet-option {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  transition: background-color 0.2s;
}
.wallet-option:hover {
  background-color: #f3f4f6;
  border-radius: 0.375rem;
}
</style>
