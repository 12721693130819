import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Aura from '@primevue/themes/aura';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import ProgressBar from 'primevue/progressbar';
import '@/assets/styles.scss';
import '@/assets/tailwind.css';
import 'primeicons/primeicons.css';
import './echo.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './fontAwesome.js';
import {NoirPreset} from "@/layout/composables/BcTheme";
import Tooltip from 'primevue/tooltip';
import InstantSearch from 'vue-instantsearch/vue3/es';


const app = createApp(App);

import { definePreset } from '@primevue/themes';



export default {
    preset: NoirPreset,
    options: {
        darkModeSelector: '.p-dark'
    }
};

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.use(store);
app.use(PrimeVue, {
    theme: {
        preset: NoirPreset,
        options: {
            darkModeSelector: '.app-dark'
        }
    }
});
app.use(ToastService);
app.component('ProgressBar', ProgressBar);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);
app.use(InstantSearch);

app.mount('#app');
