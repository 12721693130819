import { computed, reactive } from 'vue';

const layoutConfig = reactive({
    preset: 'Aura',
    primary: 'violet',
    surface: null,
    darkTheme: true,
    menuMode: 'static'
});

const layoutState = reactive({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    activeMenuItem: null
});

export function useLayout() {
    const setActiveMenuItem = (item) => {
        layoutState.activeMenuItem = item.value || item;
    };

    const toggleDarkMode = () => {
        if (!document.startViewTransition) {
            executeDarkModeToggle();

            return;
        }

        document.startViewTransition(() => executeDarkModeToggle(event));
    };

    const executeDarkModeToggle = () => {
        layoutConfig.darkTheme = !layoutConfig.darkTheme;
    
        if (layoutConfig.darkTheme) {
            document.documentElement.classList.add('app-dark');
            localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.classList.remove('app-dark');
            localStorage.setItem('theme', 'light');
        }
    };
    
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
        layoutConfig.darkTheme = true;
        document.documentElement.classList.add('app-dark');
    } else {
        layoutConfig.darkTheme = false;
        document.documentElement.classList.remove('app-dark');
    }
    

    const toggleMenu = () => {
        if (layoutConfig.menuMode === 'overlay') {
            layoutState.overlayMenuActive = !layoutState.overlayMenuActive;
        }

        if (window.innerWidth > 991) {
            layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive;
        } else {
            layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
        }
    };

    const isSidebarActive = computed(() => layoutState.overlayMenuActive || layoutState.staticMenuMobileActive);

    const isDarkTheme = computed({
        get: () => layoutConfig.darkTheme,
        set: (value) => {
            layoutConfig.darkTheme = value;
            executeDarkModeToggle();
        }
    });
    const getPrimary = computed(() => layoutConfig.primary);

    const getSurface = computed(() => layoutConfig.surface);

    return {
        layoutConfig,
        layoutState,
        toggleMenu,
        isSidebarActive,
        isDarkTheme,
        getPrimary,
        getSurface,
        setActiveMenuItem,
        toggleDarkMode
    };
}
