<template>
  <div class="ticker-wrapper">
    <div class="tradingview-widget-container w-full">
      <div class="tradingview-widget-container__widget"></div>
      <div class="tradingview-widget-copyright text-center mt-1 text-xs">
        
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useLayout } from "@/layout/composables/layout";
const { isDarkTheme } = useLayout();
onMounted(() => {
  const isDark = isDarkTheme.value;
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
  script.innerHTML = JSON.stringify({
    symbols: [
      { proName: "BITSTAMP:BTCUSD", title: "Bitcoin" },
      { proName: "BITSTAMP:ETHUSD", title: "Ethereum" },
      { description: "XRP", proName: "BINANCE:XRPUSDT" },
      { description: "ETH Tether", proName: "BINANCE:ETHUSDT" },
      { description: "BNB USDT", proName: "BINANCE:BNBUSDT" },
      { description: "SOL USDT", proName: "BINANCE:SOLUSDT" }
    ],
    showSymbolLogo: true,
    isTransparent: true,
    displayMode: "regular",
    colorTheme: isDark ? "dark":"light",
    locale: "en"
  });

  document.querySelector(".tradingview-widget-container")?.appendChild(script);
});
</script>

<style scoped>
.ticker-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50px * 15); /* 750px */
  max-width: 100%; /* Ensures responsiveness */
  margin-top: auto;
  padding-top: 8px;
}

/* Hide on mobile screens */
@media (max-width: 640px) {
  .ticker-wrapper {
    display: none;
  }
}

/* Tablet (medium screens) */
@media (max-width: 768px) {
  .ticker-wrapper {
    width: 90%;
  }
}

/* Laptops (large screens) */
@media (max-width: 1024px) {
  .ticker-wrapper {
    width: 750px;
  }
}

/* Desktops (extra-large screens) */
@media (min-width: 1280px) {
  .ticker-wrapper {
    max-width: 1200px;
  }
}

.tradingview-widget-container {
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tradingview-widget-container__widget {
  width: 100%;
}


</style>
