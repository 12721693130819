<template>
  <div class="search-pairs">
    <AisInstantSearch
      :search-client="searchClient"
      :index-name="indexName"
    >
      <AisAutocomplete>
        <template v-slot="{ currentRefinement, indices, refine }">
          <!-- search input -->
          <div class="search-input">
            <input
              type="search"
              :value="currentRefinement"
              placeholder="Search pairs..."
              @input="(e) => {
                refine(e.currentTarget.value);
                searchInput = e.currentTarget.value;
              }"
              @focus="isOpen = true"
              class="search-input-field"
            />
          </div>

          <!-- results dropdown -->
          <div v-if="isOpen && currentRefinement && indices[0].hits.length > 0" class="search-dropdown">
            <div
              v-for="hit in indices[0].hits"
              :key="hit.objectID"
              class="hit-item"
              @click="handleSelect(hit)"
            >
              <div class="hit-content">
                <div class="pair-name">{{ hit.token_name }}({{ hit.token_symbol }})</div>
                <div class="pair-stats">
                  <span>{{ formatNumber(hit.liquidity_in_eth) }} ETH</span>
                  <span>{{ hit.buy_count }} buyers</span>
                  <span>{{ hit.sell_count }} sellers</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AisAutocomplete>
    </AisInstantSearch>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { liteClient as algoliasearch } from 'algoliasearch/lite'
import { AisInstantSearch, AisAutocomplete } from 'vue-instantsearch/vue3/es'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router';

const route = useRoute();
const router = useRouter()
const isOpen = ref(false)
const searchInput = ref('')
const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID,
  import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY
)
const indexName = 'pairs'

const formatNumber = (num) => {
  if (!num) return '0.00'
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num)
}

const handleSelect = (item) => {
  searchInput.value = ''
  isOpen.value = false
  router.push(`/pairs/${item.pair_address}`)
}

// watch for route changes to close dropdown
watch(() => route.path, () => {
  isOpen.value = false
  searchInput.value = ''
})

const handleClickOutside = (event) => {
  if (!event.target.closest('.search-pairs')) {
    isOpen.value = false
    searchInput.value = ''
  }
}

// lifecycle
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

</script>

<style scoped>
.search-pairs {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 1rem;
}

.search-input {
  position: relative;
  z-index: 1001;
}

.search-input-field {
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 8px;
  background: var(--primary4);
  color: var(--secondary1);
  font-size: 1rem;
  transition: all 0.2s ease;
}

.search-input-field:focus {
  outline: none;
  background: var(--primary3);
  box-shadow: 0 0 0 2px var(--primary5);
}

.search-dropdown {
  position: fixed;
  top: 5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 30%;
  max-width: 1200px;
  background: var(--primary3);
  background-color: var(--primary3);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1002;
  max-height: calc(100vh - 7rem);
  overflow-y: auto;
  border: 1px solid var(--primary4);
  animation: slideDown 0.2s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hit-item {
  padding: 1rem;
  border-bottom: 1px solid var(--primary4);
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: var(--surface-card) !important;
  opacity: 0.8;
}

.hit-item:last-child {
  border-bottom: none;
}

.hit-item:hover {
  background: var(--primary4);
}

.hit-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.pair-name {
  font-weight: 600;
  color: var(--secondary1);
}

.pair-stats {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  color: var(--secondary2);
}
</style> 