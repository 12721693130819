import { library } from '@fortawesome/fontawesome-svg-core';

// Solid icons
import {
    faDownload,
    faCode,
    faCodeCompare,
    faFire,
    faSort,
    faSortUp,
    faSortDown,
    faCopy,
    faGasPump,
    faCube,
    faClipboard,
    faEye, faTimes,
    faTriangleExclamation,
    faCircleCheck,
    faCircleExclamation,
    faCircleQuestion,
    faScaleBalanced,
    faSackDollar,
    faRightLeft,
    faChartLine

} from '@fortawesome/free-solid-svg-icons';

// Brand icons
import { faEthereum, faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons';

// Regular icon
import { faStar } from '@fortawesome/free-regular-svg-icons';

// Add icons to library
library.add(
    faDownload,
    faCode,
    faEthereum,
    faCircleExclamation,
    faCircleQuestion,
    faCircleCheck,
    faGasPump,
    faClipboard,
    faCube,
    faCodeCompare,
    faDiscord,
    faStar,
    faTelegram,
    faFire,
    faSort,
    faSortUp,
    faSortDown,
    faCopy,
    faEye,
    faTimes,
    faScaleBalanced,
    faSackDollar,
    faRightLeft,
    faChartLine
);
