<template>
  <div class="layout-topbar">
    <!-- Logo and Menu Button -->
    <div class="layout-topbar-logo-container">
      <button
        class="layout-menu-button layout-topbar-action"
        @click="toggleMenu"
        aria-label="Toggle Menu"
      >
        <i class="pi pi-bars"></i>
      </button>
      <router-link to="/" class="layout-topbar-logo">
        <img src="/logo-small.svg" alt="Block Compass" />
      </router-link>
    </div>

    <!-- Search Pairs -->
      <SearchPairs />

    <!-- Actions -->
    <div class="layout-topbar-actions">
      <!-- WIDER TICKER -->
      <div class="ticker-container">
        <TickerTape />
      </div>

      <block-timer />
      <wallet />
      <button
        type="button"
        @click="toggleDarkMode"
        aria-label="Toggle Dark Mode"
      >
        <i :class="['pi', { 'pi-moon': isDarkTheme, 'pi-sun': !isDarkTheme }]"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useLayout } from "@/layout/composables/layout";
import BlockTimer from "@/components/BlockTimer.vue";
import Button from "primevue/button";
import TickerTape from "@/components/tradingView/TickerTape.vue";
import Wallet from "@/components/Wallet.vue";
import SearchPairs from "@/components/SearchPairs.vue";

const { toggleMenu, toggleDarkMode, isDarkTheme } = useLayout();

watch(isDarkTheme, (newTheme) => {
  document.documentElement.classList.toggle("app-dark", newTheme);
});
</script>

<style scoped>
.layout-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  height: 5rem;
  background-color: var(--primary3);
  color: var(--secondary1);
  position: relative;
  z-index: 1000;
}

/* TOPBAR ACTIONS */
.layout-topbar-actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;
  position: relative;
  z-index: 1000;
}

.ticker-container {
  flex-grow: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* SEARCH BAR */
.search-bar {
  flex: 1;
  max-width: 600px;
  margin: 0 1rem;
}

.layout-topbar-logo {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.layout-topbar-logo img {
  height: 4rem; 
  width: auto;
  max-width: 100%;
  object-fit: contain;
}
</style>
