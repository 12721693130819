import { ref } from 'vue';

const iconCache = new Map();


const ICON_APIS = [
  (address) => `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`,
  (address) => `https://assets.coingecko.com/coins/images/279/small/ethereum.png`,
  (address) => `https://tokens.1inch.io/${address.toLowerCase()}.png`,
  (address) => `https://token-icons.eth.s3.amazonaws.com/${address.toLowerCase()}.png`,
  () => null
];

const isValidImageUrl = async (url) => {
  if (!url) return false;
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok && response.headers.get('content-type')?.startsWith('image/');
  } catch {
    return false;
  }
};

export const getTokenIcon = async (address, symbol) => {
  if (iconCache.has(address)) {
    return iconCache.get(address);
  }

  for (const getIconUrl of ICON_APIS) {
    const url = getIconUrl(address);
    if (await isValidImageUrl(url)) {
      iconCache.set(address, url);
      return url;
    }
  }

  iconCache.set(address, null);
  return null;
};

export const handleImageError = () => {};
export const handleImageSuccess = () => {}; 