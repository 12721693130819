<template>
  <div class="flex flex-col  w-full mt-2">
    <h3 class="text-xs font-bold mb-2 text-center">Watchlist</h3>
    <div v-if="watchlist.length === 0" class="text-center text-gray-400 py-2">
      <i class="pi pi-list text-2xl mb-1"></i>
      <p class="text-xs">No tokens in watchlist</p>
    </div>
    
    <div v-else class="overflow-y-auto">
      <div class="space-y-1">
        <div 
          v-for="token in watchlist" 
          :key="token.pair_id"
          class="group flex items-center justify-between w-full py-1 px-2 hover:bg-gray-300 rounded transition-colors"
        >
          <div class="flex flex-col  text-center">
            <div class="flex items-center space-x-1 text-center">
              <span class="text-sm font-medium text-purple-400">{{ token.symbol }}</span>
              <span class="text-sm text-gray-400">/WETH</span>
            </div>
            <span class="text-xs font-medium" :class="getPriceColorClass(token.pair_id)">
              ${{ formatPrice((token.price)) }}
            </span>
          </div>
          
          <Button 
            icon="pi pi-times" 
            class="p-button-rounded p-button-danger p-button-text scale-75 opacity-0 group-hover:opacity-100 transition-opacity" 
            @click="removeFromWatchlist(token.pair_id)" 
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { subscribeToSwapChannel, unsubscribeFromChannel, dataStore } from '@/echo'
import format from '@/helpers/format'

const store = useStore()
const watchlist = computed(() => store.getters.getWatchlist)
const priceData = ref({})
const priceChangeDirection = ref({})
const channels = ref({})

const removeFromWatchlist = (pairId) => {
  if (channels.value[pairId]) {
    unsubscribeFromChannel(channels.value[pairId])
    delete channels.value[pairId]
  }
  store.dispatch('removeFromWatchlist', pairId)
}

const formatPrice = (price) => {
  if (!price) return '0.00'
  return format.toSignificantFigures(price, 6)
}

const getPriceForPair = (pairId) => {
  return priceData.value[pairId]?.currentPrice || 0
}

const getPriceColorClass = (pairId) => {
  const direction = priceChangeDirection.value[pairId]
  if (direction === 'up') return 'text-green-400'
  if (direction === 'down') return 'text-red-400'
  return 'text-gray-400'
}

const setupPriceSubscription = (token) => {
  if (!token.pair_id || channels.value[token.pair_id]) return

  if (!priceData.value[token.pair_id]) {
    priceData.value[token.pair_id] = {
      currentPrice: 0
    }
  }

  const channel = subscribeToSwapChannel(token.pair_id, token)
  channels.value[token.pair_id] = channel

  watch(() => dataStore.value[token.pair_id], (newData) => {
    if (newData?.currentPrice !== undefined) {
      const oldPrice = priceData.value[token.pair_id]?.currentPrice
      priceData.value[token.pair_id] = {
        currentPrice: newData.currentPrice
      }
      
      if (oldPrice !== undefined) {
        priceChangeDirection.value[token.pair_id] = newData.currentPrice > oldPrice ? 'up' : 'down'
        setTimeout(() => {
          priceChangeDirection.value[token.pair_id] = null
        }, 1000)
      }
    }
  }, { deep: true })
}

onMounted(() => {
  store.dispatch('initializeWatchlist')
  watchlist.value.forEach(setupPriceSubscription)
})

watch(watchlist, (newWatchlist) => {
  newWatchlist.forEach(setupPriceSubscription)
}, { deep: true })

onUnmounted(() => {
  Object.values(channels.value).forEach(channel => {
    if (channel) {
      unsubscribeFromChannel(channel)
    }
  })
})
</script>

<style lang="scss" scoped>
:deep(.p-button.p-button-icon-only) {
  width: 1.25rem !important;
  height: 1.25rem !important;
  padding: 0 !important;
}

:deep(.p-button .p-button-icon) {
  font-size: 0.65rem !important;
}

:deep(.p-button.p-button-text:hover) {
  background: transparent !important;
  color: var(--red-500) !important;
}
</style>