import { ethers } from 'ethers';
import { ERC20_ABI } from '@/abi/erc20';

export const truncateAddress = (address) => {
  if (!address) return '';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const formatEthBalance = (balanceWei) => {
  if (balanceWei === null) return '0.00';
  return parseFloat(ethers.formatEther(balanceWei)).toFixed(4);
};

export const formatTokenBalance = (balance, decimals = 18) => {
  if (balance === null) return '0.00';
  return parseFloat(ethers.formatUnits(balance, decimals)).toFixed(4);
};

export const getProvider = () => {
  if (window.ethereum) {
    return new ethers.BrowserProvider(window.ethereum);
  } else if (window.walletconnect) {
    return new ethers.BrowserProvider(window.walletconnect);
  }
  return null;
};

export const getEthBalance = async (address) => {
  try {
    const provider = getProvider();
    if (provider && address) {
      const balanceWei = await provider.getBalance(address);
      return balanceWei.toString();
    }
    return '0';
  } catch (error) {
    console.error('Error fetching ETH balance:', error);
    return '0';
  }
};

export const getTokenBalance = async (tokenAddress, ownerAddress) => {
  try {
    const provider = getProvider();
    if (!provider || !tokenAddress || !ownerAddress) return null;

    const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
    const [balance, decimals, symbol, name] = await Promise.all([
      contract.balanceOf(ownerAddress),
      contract.decimals(),
      contract.symbol(),
      contract.name()
    ]);

    return {
      balance,
      decimals,
      symbol,
      name,
      formatted: formatTokenBalance(balance, decimals)
    };
  } catch (error) {
    console.error('Error fetching token balance:', error);
    return null;
  }
};

export const getTokenBalances = async (tokenAddresses, ownerAddress) => {
  try {
    const balances = await Promise.all(
      tokenAddresses.map(address => getTokenBalance(address, ownerAddress))
    );
    return balances.filter(Boolean); // Remove null results
  } catch (error) {
    console.error('Error fetching token balances:', error);
    return [];
  }
}; 